import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import SwiperCore from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

import { OutboundLink } from "gatsby-plugin-google-gtag";
import Arrow from "../../images/arrow-right.inline.svg";
import Play from "../../images/playBtn.inline.svg";
import Pause from "../../images/pauseBtn.inline.svg";
import bg from "../../images/index/slider.svg";
const proj = [
  {
    title: "Firedancer",
    info: "Firedancer is a validator client for Solana being developed by Jump Crypto and engineered to optimize networking throughput, resilience and efficiency.",

    buttonLabel: ".Explore Firedancer",
    buttonUrl: "https://jumpcrypto.com/firedancer/",
  },
  {
    title: "thePit",
    info: "The Pit is a community space designed to bring together serious developers, artists, and everyone in between to collaborate, build, and ship code.",

    buttonLabel: ".Explore the Pit",
    buttonUrl: "https://jumpcrypto.com/thepit/",
  },
  {
    title: "Cyclone",
    info: "Cyclone marries Jump’s expertise in hardware and FPGA design to accelerate the core components of zero-knowledge-proof systems. The code is open-source and out in the open for builders to leverage for their projects.",
    buttonLabel: ".Explore Cyclone",
    buttonUrl: "https://jumpcrypto.com/cyclone/",
  },
  {
    title: "Silo",
    info: "Project Silo seeks to solve ongoing limitations associated with custody solutions, especially as more diverse sets of users and institutions invest time and energy into Web3. Its focus is to both build custody solutions that suit Jump’s needs and contribute back to the industry via open source.",
    buttonLabel: ".Explore GitHub Repository",
    buttonUrl:
      "https://github.com/orgs/JumpCrypto/repositories?q=crosschain+OR+chasm+OR+silo",
  },
];

const Projects = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [playVideos, setPlayVideos] = useState(true);
  const [counter, setCounter] = useState(0);
  const breakpoints = useBreakpoint();
  const handleOnSetSwiper = (index: number) => {
    swiperInstance?.slideTo(index);
    setSwiperIndex(index);
  };

  useEffect(() => {
    if (
      !breakpoints.xs &&
      !breakpoints.sm &&
      Object.keys(breakpoints).length !== 0
    ) {
      const videos = document.querySelectorAll("video");
      videos.forEach((v) => {
        if (playVideos) {
          v.play();
        } else {
          v.pause();
        }
      });
    }
  }, [playVideos, breakpoints]);

  React.useEffect(() => {
    setInterval(() => setCounter(counter + 5), 1000);
  }, [counter]);

  return (
    <Box
      component="div"
      sx={{
        maxWidth: 1044,
        mx: "auto",
        mt: 6,
        mb: { xs: 5, md: 16 },
        position: "relative",
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "24px",
          mb: 4,
        }}
      >
        {proj.map((p, index) => (
          <Button
            key={index}
            sx={{
              background: swiperIndex == index ? "#26385F" : "#091A3D",
              border: "1px solid #4B608D",
              borderRadius: 0,
              height: "46px",
              position: "relative",
              px: 3.2,
              fontSize: "15px",
              "&:before": {
                content: '""',
                position: "absolute",
                background: swiperIndex == index ? "#26385F" : "#091A3D",
                border: "1px solid #4B608D",
                width: "100%",
                height: "100%",
                top: -10,
                left: 10,
                zIndex: -1,
                transition:
                  "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              },
              "&:hover": {
                backgroundColor: "#26385F",
                "&:before": {
                  backgroundColor: "#26385F",
                },
              },
            }}
            onClick={() => handleOnSetSwiper(index)}
          >
            {p.title}
          </Button>
        ))}
      </Box>

      <Swiper
        modules={[Autoplay]}
        onSwiper={setSwiperInstance}
        onSlideChange={(swiper) => setSwiperIndex(swiper.realIndex)}
        touchRatio={0}
        simulateTouch={false}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
      >
        {proj.map((p) => (
          <SwiperSlide key={p.title}>
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { xs: "column-reverse", sm: "row" },
                alignItems: "center",
              }}
            >
              <Box
                component="div"
                sx={{
                  width: { xs: "100%", sm: "50%" },
                  pr: { sm: 3.5 },
                  pt: 1,
                }}
              >
                <Typography variant="h2" sx={{ mb: 2.4 }}>
                  {p.title}{" "}
                </Typography>
                <Typography> {p.info}</Typography>
                <Button
                  variant="outlined"
                  component={OutboundLink}
                  href={p.buttonUrl}
                  sx={{ mt: 4 }}
                  endIcon={<Arrow />}
                >
                  {p.buttonLabel}
                </Button>
              </Box>
              <Box
                component="div"
                sx={{
                  width: { xs: "100%", sm: "50%" },
                  display: { xs: "none", sm: "block" },
                }}
              >
                <Box component="img" src={bg} alt="" />
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Projects;
